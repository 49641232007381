var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"templates-manager"},[_c('div',{staticClass:"banner"}),_c('v-container',[_c('div',{staticClass:"new-button"},[_c('v-btn',{staticClass:"new",attrs:{"color":"secondary darken-1","to":"templates/new","large":""}},[_vm._v("+ New template")])],1),_c('v-card',[_c('v-card-title',[_vm._v("Template list")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.songHeaders,"items":_vm.documents,"loading":_vm.loadingDocuments,"sort-by":_vm.documentParams.sort,"sort-desc":_vm.documentParams.desc,"server-items-length":25,"items-per-page":25,"hide-default-footer":"","item-key":"uuid"},on:{"update:sort-by":_vm.sortDocuments,"update:sort-desc":_vm.orderDesc},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('router-link',{staticClass:"document-name",attrs:{"to":("/templates/" + (item.id))}},[_vm._v(_vm._s(item.name))]):_c('router-link',{staticClass:"document-name",attrs:{"to":("/templates/" + (item.id))}},[_vm._v("[no title]")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [(item.created_at)?_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))]):_c('span',{staticClass:"n-a"},[_vm._v("N/A")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [(item.updated_at)?_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.formatDate(item.updated_at)))]):_c('span',{staticClass:"n-a"},[_vm._v("N/A")])]}},{key:"loading",fn:function(){return [_c('div',{staticClass:"table-spinner"},[_c('Spinner')],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions-holder"},[_c('v-tooltip',{attrs:{"left":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-button",attrs:{"color":"secondary","to":("/templates/" + (item.id)),"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"action"},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v("Edit template")]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-button",attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.newFromTemplate(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"action"},[_vm._v("mdi-file-outline")])],1)]}}],null,true)},[_vm._v("New contract with this template")]),_c('v-tooltip',{attrs:{"left":"","color":"accent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-button",attrs:{"color":"accent","small":"","icon":""},on:{"click":function($event){return _vm.deleteTemplate(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"action"},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_vm._v("Delete template")])],1)]}}])}),_c('v-pagination',{attrs:{"length":_vm.pages,"color":"secondary"},on:{"input":_vm.getPage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }