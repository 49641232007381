<template lang="pug">
  .document-sent
      v-container
        v-row
            v-col(sm="12")
                DashboardWidget
                    template(#title)
                    .icon
                        v-icon(color="green darken-1") mdi-email-check
                    .text Your contract was sent successfully
                    .actions
                        v-btn.dashboard(outlined color="green" :to="{name: 'dashboard'}") Go to your dashboard
                        v-spacer
                        v-btn.save(color="primary" :to="{name: 'documents_manager'}") Go to the contracts table

</template>

<script>
import DashboardWidget from "../../components/dashboard/DashboardWidget";
import EditorPreview from "../../components/EditorPreview";

export default {
    components: {EditorPreview, DashboardWidget},
    methods: {
    },

}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.document-sent{
    height: 100%;
    display: flex;

    align-items: center;
}

::v-deep .widget{
    width: 100%;
    display: flex;
    padding: 15px;
}

.actions{
    display: flex;
}

.icon{
    text-align: center;

    margin-top: 75px;

    .v-icon{
        font-size: 175px;
    }
}

.text{
    font-weight: 700;
    font-size: 55px;

    text-align: center;
    color: $color-green--normal;
    padding-top: 75px;
    padding-bottom: 150px;
}
</style>
