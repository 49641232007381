<template lang="pug">
    v-card(:class="[`color-${color}`]")
        v-card-title.editor-title
            v-tabs(v-model="editorTab" centered :color="color")
                v-tab(key="0")
                    v-icon mdi-pencil
                    | Create
                v-tab(key="1")
                    v-icon mdi-eye-outline
                    | Preview
        v-card-text.editor-holder
            v-tabs-items(v-model="editorTab" )
                v-tab-item(key="0")
                    .toolbar-holder
                        .toolbar-overlay(v-if="selectedTemplate")
                            .overlay-items
                                .overlay-title Previewing template
                                    strong &nbsp;{{selectedTemplate.name}}
                                .overlay-buttons
                                    v-btn(color="primary" outlined large @click="dismissTemplate") Dismiss
                                    v-spacer
                                    v-btn(color="primary" large @click="applyTemplate") Apply
                        .alert-holder
                            Alert(:color="color" dismissible dense v-if="type === 'document'") Give your new contract a name and start writing. Everything you type will get saved automatically as draft.
                            Alert(:color="color" dismissible dense v-else) Edit both your document contents and email message here. Any template contents applied to new contracts will be editable.
                        .document-data(v-if="type === 'document'")
                            v-text-field(:color="color" placeholder="Contract name" outlined dense v-model="name")
                            v-text-field(:color="color" placeholder="Sign contract as" outlined dense v-model="signee")
                                template(v-slot:append)
                                    v-tooltip(:color="color" top :open-on-focus="false" :open-on-click="false") Set name as the current user
                                        template(#activator="{ on, attrs }")
                                            v-icon(@click="setAsUser" v-bind="attrs" v-on="on") mdi-account-outline
                        .template-data(v-else)
                            v-text-field(:color="color" placeholder="Template name" outlined dense v-model="name")
                            v-textarea(:color="color" placeholder="Template description" outlined dense v-model="description")
                        .toolbar
                            EditorToolbar(:editor="documentEditor")
                    Editor(v-model="document" :main="true" :json.sync="documentJson" name="document" :editor.sync="documentEditor" v-show="!selectedTemplate")
                    Editor.template-view(v-model="selectedTemplate.html" name="document" v-if="selectedTemplate")
                v-tab-item(key="1")
                    .toolbar-holder
                        .toolbar-overlay(v-if="selectedTemplate")
                            .overlay-items
                                .overlay-title Previewing template
                                    strong &nbsp;{{selectedTemplate.name}}
                                .overlay-buttons
                                    v-btn(color="primary" outlined large @click="dismissTemplate") Dismiss
                                    v-spacer
                                    v-btn(color="primary" large @click="applyTemplate") Apply

                        .alert-holder(v-if="previewAlert")
                            Alert(:color="color" dismissible dense v-model="previewAlert") This is a preview of the document you are currently editing. By default you are seeing a global Schedule A table for all rightsholders in this contract. Untick
                                strong &nbsp;ALL PEOPLE SHOWN
                                | &nbsp;and select a rightsholder from the dropdown for individual Schedule A tables.
                        .toolbar.preview-toolbar
                            v-select(placeholder="Select rightsholder for Schedule A preview" :color="color" outlined dense :disabled="showAllSchedules" :items="availableNames" item-text="name" return-object @input="selectRightsholder")
                            v-spacer
                            v-switch.show-all-rightsholders(:color="color" :label="allRightsholders" inset v-model="showAllSchedules")
                    EditorPreview(:value="preview")
</template>

<script>
import Editor from "@/components/Editor";
import EditorPreview from "./EditorPreview";
import EditorToolbar from "./EditorToolbar";
import Alert from "./Alert";
import {mapState, mapGetters} from "vuex";

export default {
    name: "EditorTab",
    props: ['color', 'type'],
    components: { Alert, EditorToolbar, EditorPreview, Editor},
    saveDocument(){
        this.updateDocument();
        return this.saveDocument();
    },
    data(){
        return {
            document: '',
            email:'',
            documentJson: '{}',
            documentEditor: null,
            editorTab: 0,
            showAllSchedules: this.$store.state.globalSchedule,
            previewAlert: true,
            name: '',
            description: '',
            signee: ''
        }
    },
    methods: {
        dismissTemplate(){
            this.$store.commit('setTemplate', null);
        },
        applyTemplate(){
            this.document = this.selectedTemplate.html;
            this.json = this.selectedTemplate.json
            this.email = {
                html: this.selectedTemplate.email_html,
                json: this.selectedTemplate.email_json
            }
            this.$store.commit('setTemplate', null);
        },
        setAsUser(){
            this.signee = `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`
        },
        async updateDocument(){
            this.$store.commit('setEditedDocument', {
                name: this.name,
                signee: this.signee,
                description: this.description,
                document: {
                    html: this.document,
                    json: this.documentJson,
                }
            })
        },
        async updateEmail(){
            this.$store.commit('setEditedEmail', {
                email: {
                    html: this.email.html,
                    json: this.email.json
                }
            })
        },
        async saveDocument(){
            if(!this.document && !this.name){
                return;
            }
            const documentId = this.editedDocument.id;
            const isDocument = this.$route.name.includes('contract');
            let action = 'saveDocument';
            let path = 'contracts';
            if(!isDocument){
                action = 'saveTemplate';
                path = 'templates';
            }
            const document = await this.$store.dispatch(action, {
                id: documentId || 'new',
                data: this.editedDocument
            })
            if(!documentId && document.id){
                await this.$router.replace(`/${path}/` + document.id);
                this.$store.commit('setEditedDocumentFromApi', document);
            }
        },
        selectRightsholder(person){
            this.$store.commit('setSelectedPerson', person);
        }
    },
    computed: {
        preview(){
            if(this.selectedTemplate){
                return this.selectedTemplate.html;
            }
            return this.document;
        },
        allRightsholders(){
            if(this.showAllScheduleAs){
                return 'All people shown'
            }
            return 'Selected shown'
        },
        ...mapState({
            selectedTemplate: state => state.selectedTemplate,
            editedDocument: state => state.editedDocument,
        }),
        ...mapGetters([
            'availableNames'
        ])
    },
    mounted() {
        this.$store.commit('setTemplate', null);
        clearInterval(this.$store.state.saveInterval);
        this.saveDocument();
        const saveInterval = setInterval(this.saveDocument, 30000);
        this.$store.commit('setSaveInterval', saveInterval);
    },
    beforeDestroy() {
        clearInterval(this.$store.state.saveInterval);
    },

    watch: {
        document(){
            this.updateDocument();
        },
        name(){
            this.updateDocument();
        },
        description(){
            this.updateDocument();
        },
        email(){
            this.updateEmail();
        },
        showAllSchedules(){
            this.$store.commit('setGlobalSchedule', this.showAllSchedules);
        },
        signee(){
            this.$store.commit('setSigningUser', this.signee);
        },
        '$store.state.user': function(){
            this.setAsUser();
        },
        editedDocument: {
            immediate: true,
            handler: function(){
                if(this.editedDocument && this.editedDocument.document){
                    this.document = this.editedDocument.document.html
                    this.name = this.editedDocument.name;
                    this.signee = this.editedDocument.signee || this.$store.state.signingUser;
                    this.documentJson = this.editedDocument.document.json;
                    this.description = this.editedDocument.description;
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/_variables.scss';

.v-card__title{
    box-shadow: 0px 4px 7px rgba(50, 50, 50, 0.1);
}

.v-tab{
    text-transform: none;

    .v-icon{
        margin-right: 10px;
    }
}

.v-sheet.v-card{

    .v-card__title{
        border-bottom: none !important;
        padding: 0px;

        overflow: hidden;
    }

    .v-card__text .v-tabs-items{
        background: transparent;
    }
}

.template-view{
    pointer-events: none;
}

.toolbar-holder{
    position: relative;
}

.toolbar-overlay{
    width: 100%;
    height: calc(100% + 1rem);
    position: absolute;
    top: -1rem;
    left: 0px;
    background: rgba(250,250,255, 0.5);
    z-index: 100;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay-title{
        font-size: 2rem;
        margin-bottom: 35px;
    }

    .overlay-buttons{
        display: flex;
    }
}

::v-deep .v-item-group{
    width: 100%;
}

.template-data{
    padding: 1rem;
}

.document-data{
    padding: 1rem;
}

.editor-holder{
    padding: 0px;
}

.v-textarea::v-deep textarea{
    height: 35px;
    transition: height 0.25s;

    &:focus{
        height: 150px;
    }

    &:hover{
        height: 150px;
    }
}

.alert-holder + .toolbar.preview-toolbar{
    margin-top: 15px;
}

.toolbar{
    background: #FFFFFF;
    text-align: center;
    box-shadow: 0px 25px 50px rgba(50, 50, 50, 0.05);
    box-shadow: 0px 1px 7px rgba(50, 50, 50, 0.1), 0px 25px 50px rgba(50, 50, 50, 0.05), 0px 50px 75px rgba(242,248,250, 1);

    .color-primary &{
        box-shadow: 0px 25px 50px rgba(50, 50, 50, 0.05);
        box-shadow: 0px 1px 7px rgba(50, 50, 50, 0.1), 0px 25px 50px rgba(50, 50, 50, 0.05), 0px 50px 75px rgba(250,250,255, 1);
    }

    &.preview-toolbar::v-deep{
        display: flex;
        padding: 1rem;

        margin-top: -10px;

        .v-messages{
            display: none;
        }

        .v-input--switch{
            margin-top: 0px;
            border: 1px solid $color-secondary--bright;
            padding: 7px;
            padding-left: 15px;
            padding-right: 15px;
            height: 40px;
            border-radius: 5px;

            label{
                color: $color-secondary--normal;
                text-transform: uppercase;

                transition: none;
            }

            &:hover{
                background-color: $color-secondary--bright;

                label{
                    color: $color-secondary--normal;
                }
            }

            &.v-input--is-label-active{
                background-color: $color-secondary--bright;
                color: $color-secondary--normal;

                label{
                    color: $color-secondary--normal;
                }
            }

            .v-input__slot{
            }
        }

        .v-select{
            width: 0px;

            .v-text-field__details{
                display: none;
            }

            &.v-input--is-disabled{
                opacity: 0.35;
                user-select: none;
            }
        }
    }
}

.show-all-rightsholders{
    width: 260px;
}

::v-deep {

    .editor-preview {
        min-height: 500px;
    }
    .v-window{
        overflow: visible;
    }
}

.alert-holder{
    padding-left: 1rem;
    padding-right: 1rem;
}

</style>
