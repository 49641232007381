<template lang="pug">
    .related-songs-holder
        Alert(color="primary" dismissible) Here you will select the songs which are used for this contract. The rightsholders will be automatically extracted from them.
        .related-songs(:class="{empty: songs.length === 0}")
            .empty(v-if="songs.length === 0")
                strong No songs selected for this contract
                div Add some using the button below
            .songs(v-else)
                .related-song(v-for="song in songs" :key="song.uuid")
                    RelatedSong(:song="song")
        v-dialog(v-model="songsPopup" transition="dialog-top-transition" width="750")
            template(#activator="{ on, attrs }")
                v-btn(color="primary" outlined block v-bind="attrs" v-on="on") Add songs in list
            v-card
                v-card-title Add song in contracts
                v-card-text
                    v-row.song-filter
                        v-col(sm="7")
                            v-text-field(v-model="songParams.search" placeholder="Search by song name, artist, or rightsholder" dense outlined clearable)
                            //v-combobox(v-model="search" placeholder="Search by song name, artist, or rightsholder" dense outlined clearable)
                        v-col(sm="5")
                            v-select(:items="sortItems" v-model="sort" item-text="name" :prepend-icon="selectedSort.icon" dense outlined menu-props="auto")

                    //.song-sort
                    //    div Sort by:
                        //v-select(:items="sortItems" v-model="sort" item-text="name" :prepend-icon="selectedSort.icon" dense)
                    .spinner-holder(v-if="loading")
                        Spinner
                    .songs-holder(v-else)
                        v-virtual-scroll(height="500" item-height="90" :items="popupSongs" bench="5")
                            template(#default="{item}")
                                Song(:song="item" :key="item.uuid" @input="updateSelected")
                v-card-actions
                    v-btn(color="primary" outlined @click="dismiss") Dismiss
                    v-spacer
                    div {{this.selectedSongs.size}} song(s) selected
                    v-spacer
                    v-btn(color="primary" @click="save") Save
</template>

<script>
import api from '@/api';
import RelatedSong from "./RelatedSong";
import Alert from "./Alert";
import Spinner from "./Spinner";
import {mapState} from 'vuex';
import Song from "./Song";

export default {
    components: {Song, Spinner, Alert, RelatedSong},
    props: ['song'],

    data() {
        return {
            songsPopup: false,
            search: '',
            loading: true,
            songParams: {
                search: '',
                sort: 'name',
                desc: null,
                ignored_uuids: [],
                paginate: 25
            },
            sort: 'name',
            popupSongs: [],
            selectedSongs: new Map(),
            sortItems: [
                {
                    icon: 'mdi-sort-calendar-ascending',
                    'name': 'Date ascending',
                    'value': 'date'
                },
                {
                    icon: 'mdi-sort-calendar-descending',
                    'name': 'Date descending',
                    'value': '-date'
                },
                {
                    icon: 'mdi-sort-alphabetical-ascending',
                    'name': 'Name ascending',
                    'value': 'name'
                },
                {
                    icon: 'mdi-sort-alphabetical-descending',
                    'name': 'Name descending',
                    'value': '-name'
                },
            ],
        }

    },

    computed: {
        selectedSort(){
            return this.sortItems.find(item => item.value === this.sort);
        },
        ...mapState({
            songs: state => state.selectedSongs,
            editedDocument: state => state.editedDocument
        })
    },


    methods: {
        async save(){
            this.$store.commit('setSelectedSongs', [...this.songs, ...this.selectedSongs.values()])
            this.$store.commit('setEditedDocument', {...this.editedDocument, songs: this.songs.map(song => song.uuid)});
            this.dismiss();
        },
        dismiss(){
            this.songParams.search = '';
            this.songsPopup = false;
        },
        async getSongs(){
            this.loading = true;
            this.songParams.ignored_uuids = this.songs.map(song => song.uuid);
            const songs = await api.getFullSongs(this.songParams);
            songs.items.filter(song => !this.songs.includes(song)).forEach(song => song.uuid && (song.selected = this.selectedSongs.has(song.uuid)));
            this.popupSongs = songs.items;
            this.loading = false;
        },
        updateSelected(song){
            if(song.selected){
                this.selectedSongs.set(song.uuid, song);
            }else{
                this.selectedSongs.delete(song.uuid, song);
            }
            this.selectedSongs = new Map(this.selectedSongs);
        }
    },
    beforeMount() {
        //this.songs.items.forEach(song => song.selected = false);
        //this.getSongs();
    },
    watch: {
        songsPopup (){
            if(this.songsPopup){
                this.getSongs();
            }else{
                this.popupSongs = [];
            }
        },
        'songParams.search'(){
            clearTimeout(this.interval);
            this.interval = setTimeout(() => this.getSongs(), 500)
        },
        sort(){
            if(this.sort[0] === '-'){
                this.songParams.desc = true
                this.songParams.sort = this.sort.substring(1);
            }else{
                this.songParams.desc = null
                this.songParams.sort = this.sort;
            }
            this.getSongs()
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';


.related-songs{
    height: 500px;
    overflow-y: auto;

    margin-top: 15px;
    margin-bottom: 25px;
    border: 1px solid $color-gray--25;

    &.empty{
        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;

        strong{
            display: block;
            margin-bottom: 0.5rem;
        }
    }

    .related-song{
    }

    .spinner{
        transform: scale(50%);
    }

}
.v-input::v-deep .v-text-field__details {
    display: none;
}
.song-filter{
    margin-bottom: 1rem;
}
.song-sort::v-deep{
    display: flex;
    align-items: center;

    font-size: 1rem;
    margin-bottom: 1.2rem;
    margin-top: 1rem;

    .v-input{
        width: 0px;
        margin-left: 1rem;

        .v-input__icon{
            position: relative;
            transform: translateY(-2px);
        }

        .v-input__slot:before{
            display: none;
        }

        .v-input__slot:after{
            display: none;
        }
    }
}

.spinner-holder{
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.songs-holder{

    .song{
        margin-bottom: 1rem;
    }
}
</style>
