<template lang="pug">
    .related-templates-holder
        Alert(color="primary" dismissible) Here you will select the songs which are used for this contract. The rightsholders will be automatically extracted from them.
        .related-templates(:class="{loading, empty: templates.length === 0}")
            Spinner(v-if="loading")
            .empty(v-else-if="templates.length === 0") No templates available
            .templates(v-else)
            .template(v-for="template in templates" :key="template.id")
                .template-info(:class="{selected: selected && selected.id === template.id}" @click="setSelected(template)")
                    .name {{template.name}}
                    v-spacer
                    .preview(v-if="selected && selected.id === template.id") Previewing
                    .date(v-else) {{formatDate(template.created_at)}}
                .template-dropdown(v-show="selected && selected.id === template.id && template.description") {{template.description}}
        v-snackbar(v-model="saved" color="secondary") New template added

        v-dialog(v-model="templatePopup" transition="dialog-top-transition" width="500")
            template(#activator="{ on, attrs }")
                v-btn(color="primary" outlined block v-bind="attrs" v-on="on" :disabled="selected !== null") Save contract as template
            v-card
                v-card-title Save document as new template
                v-card-text
                    v-text-field(v-model="name" placeholder="Template name" dense outlined)
                    v-textarea(v-model="description" placeholder="Template description" dense outlined)
                v-card-actions
                    v-btn(color="primary" outlined @click="dismiss") Dismiss
                    v-spacer
                    v-btn(color="primary" @click="save") Save
</template>

<script>
import api from '@/api';
import RelatedSong from "./RelatedSong";
import Spinner from "./Spinner";
import Alert from "./Alert";
import { format } from 'date-fns'
import { mapState } from 'vuex'

export default {
    components: {Alert, Spinner, RelatedSong},
    props: ['song'],

    data() {
        return {
            templates: [],
            templatePopup: false,


            loading: true,
            name: '',
            description: '',
            saved: null,
        }
    },
    computed: {
        ...mapState({
            selected: state => state.selectedTemplate,
            document: state => state.editedDocument,
            email: state => state.editedEmail
        })
    },
    methods: {
        formatDate(date){
            return format(new Date(date), 'MM/dd/yyyy')
        },
        setSelected(template){
            if(!this.selected || this.selected.id !== template.id){
                this.$store.commit('setTemplate', template)
            }else{
                this.$store.commit('setTemplate', null)
            }
        },

        async save(){
            const template = {
                ...this.document,
                ...this.email,
                name: this.name,
                description: this.description
            }
            await api.newTemplate(template);
            this.templates = await api.getTemplates();
            this.saved = true;
            this.templatePopup = false;
        },
        dismiss(){
            this.templatePopup = false;
        }
    },
    watch: {
        templatePopup(){
            if(this.templatePopup){
                this.name = this.document.name;
            }
        }
    },
    async beforeMount(){
        this.templates = await api.getTemplates();
        this.loading = false;
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';


.related-templates{
    height: 500px;
    overflow-y: auto;

    margin-top: 15px;
    margin-bottom: 25px;
    border: 1px solid $color-gray--25;

    &.loading{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.empty{
        display: flex;
        justify-content: center;
        align-items: center;

        .empty{
            font-weight: bold;
        }
    }

    .template{
        border-bottom: 1px solid $color-gray--25;

        .template-info{
            display: flex;
            padding: 15px;
            color: $color-primary--normal;
            cursor: pointer;
            font-size: 1rem;


            &:hover{
                background: $color-primary--bright;
            }

            &.selected{
                background: $color-primary--bright;
            }

        }

        .template-dropdown{
            padding: 1rem;
        }

        .date{
            color: $color-gray--75;
        }
    }

    .related-song{
    }

    .preview{
        text-transform: uppercase;
        color: $color-secondary--normal;
    }

    .spinner{
        transform: scale(50%);
    }
}
</style>
