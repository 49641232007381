<template lang="pug">
  .document-preview
      v-container
          .actions
              v-btn.dismiss(outlined color="primary" :to="{name: 'documents_manager'}") Back
              v-spacer
              v-btn.duplicate-template(color="secondary" @click="duplicateDocumentInTemplate") Use as template
              v-btn.duplicate(color="primary" @click="duplicateDocument") Use as draft

          v-row
              v-col(sm="8")
                  .document-preview
                      DashboardWidget(title="Preview")
                        EditorPreview(:value="document.html" v-if="document")
              v-col(sm="4")
                  .document-statuses
                      DashboardWidget(title="Status")
                          v-timeline(align-top dense v-if="document")

                              v-timeline-item(:color="activities[activity.type].color" small v-for="activity in document.activities")
                                  div {{activities[activity.type].template}}
                                    strong(:class="activity.type")(v-if="activity.person") &nbsp;{{activity.person}}
                                  div.date {{formatDate(activity.timestamp)}}
                                  div.download(v-if="activity.type === 'signed'" @click="downloadPdf(activity)") Download copy as PDF

</template>

<script>
import api from '@/api';
import DashboardWidget from "../../components/dashboard/DashboardWidget";
import {format} from "date-fns";
import EditorPreview from "../../components/EditorPreview";

export default {
    components: {EditorPreview, DashboardWidget},
    data(){
        return {
            id: this.$route.params.id,
            duplicated: true,
            document: null,
            templateDuplicated: null,
            activities: {
                draft: {
                    template: 'Document was saved as draft',
                    color: 'secondary'
                },
                sent: {
                    template: 'Document was sent for signing',
                    color: 'primary'
                },
                read: {
                    template: 'Document was read by',
                    color: 'amber'
                },
                signed: {
                    template: 'Document was signed by',
                    color: 'green'
                },
            }
        }
    },
    methods: {
        formatDate(date){
            return format(new Date(date), 'MM/dd/yyyy hh:mmaaa')
        },
        async duplicateDocumentInTemplate(){
            await this.$router.push({ name: 'new_template'});
            this.$store.commit('setEditedDocumentFromApi', {...this.document, id: null});
        },
        async duplicateDocument(){
            await this.$router.push({ name: 'new_contract'});
            this.$store.commit('setEditedDocumentFromApi', {...this.document, id: null});
            this.duplicated = true;
        },
        async downloadPdf(activity){
            await api.downloadDocumentAsPdf(activity.email_id, `${activity.document} - ${activity.person}`);
        }
    },

    async beforeMount(){
        this.$store.commit('showSpinner', true);
        this.document = await api.getDocument(this.id);
        this.$store.commit('showSpinner', false);
        this.$store.commit('setGlobalSchedule', true);
        if(this.document.songs){
            this.$store.commit('setSelectedSongs', await api.getSongsByUUID(this.document.songs))
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.actions {
    display: flex;
    padding-top: 25px;
    padding-bottom: 35px;

    .duplicate-template{
        margin-right: 15px;
    }
}


::v-deep .widget .v-card__title{
    box-shadow: 0px 4px 7px rgba(50, 50, 50, 0.1);
    margin-bottom: 25px;
}

strong.draft{
    color: $document-draft-color;
}

strong.sent{
    color: $document-sent-color;
}

strong.read{
    color: $document-read-color;
}

strong.signed{
    color: $document-signed-color;
}

div.download{
    font-size: 0.75rem;
    text-decoration: none;
    color: $color-primary--normal;
    cursor: pointer;

    &:hover{
        text-decoration: underline;
    }
}

::v-deep .preview{
    padding: 0px;
}

::v-deep .schedule-a .v-card__title{
    //display: none;
    box-shadow: none;
}

.actions{

    .v-btn{

        &.v-btn--outlined::before{
            background: transparent;
        }
    }
}
</style>
