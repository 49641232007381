<template lang="pug">
    .alert
        v-alert(
            border="left"
            :color="color"
            elevation="1"
            dark
            text
            icon="mdi-information-outline"
            prominent
            :dismissible="dismissible"
            close-icon="mdi-close"
            :dense="dense"
            v-model="alert"
            @input="input"
        )
            slot

</template>

<script>
export default {
    props: ['color', 'dismissible', 'dense', 'value'],
    data(){
        return {
            alert: this.value
        }
    },
    methods: {
        input(){
            this.$emit('input', this.alert);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';




.alert::v-deep {
    .v-alert{
        border-radius: 10px;
        margin-bottom: 0px;
    }

    .v-alert--prominent .v-alert__icon.v-icon:after {
        background-color: transparent !important;
        display: none;
    }
}
</style>
