<template lang="pug">
    v-card(:class="[`color-${color}`]")
        v-card-title
            v-tabs(v-model="sidebarTab" :color="color" centered )
                v-tab(key="0" v-if="color==='primary'")
                    v-icon mdi-playlist-music-outline
                    | Songs
                v-tab(key="1")
                    v-icon mdi-email-outline
                    | Message
                v-tab(key="2" v-if="color==='primary'")
                    v-icon mdi-file-replace-outline
                    | Templates
        v-card-text
            v-tabs-items(v-model="sidebarTab")
                v-tab-item(key="0" v-if="color==='primary'")
                    RelatedSongs
                v-tab-item(key="1")
                    Alert(:color="color" dismissible v-if="type ==='document'") Optionally write a custom email message which will get sent with this contract.
                    Alert(:color="color" dismissible v-else) Optionally write a custom email message. This will get applied to new contracts using this template.
                    .email-message
                        .email-toolbar
                            EditorToolbar(:editor="emailEditor")
                        Editor(v-model="email" :json.sync="emailJson" name="email" :editor.sync="emailEditor")
                v-tab-item(key="2" v-if="color==='primary'")
                    RelatedTemplates
</template>

<script>
import Alert from "./Alert";
import Editor from "@/components/Editor";
import EditorToolbar from "./EditorToolbar";
import RelatedSongs from "./RelatedSongs";
import RelatedTemplates from "./RelatedTemplates";
import {mapState} from "vuex";

export default {
    name: "RelatedTab",
    components: {RelatedTemplates, RelatedSongs, Alert,  Editor, EditorToolbar},
    props: ['color', 'type'],

    data(){
        return {
            email: '',
            emailJson: '{}',
            emailEditor: null,
            sidebarTab: 0,
        }
    },
    methods: {
        updateEmail(){
            this.$store.commit('setEditedEmail', {
                email: {
                    html: this.email,
                    json: this.emailJson,
                }
            })
        },
    },

    computed:{
        ...mapState({
            editedDocument: state => state.editedDocument,
        }),
    },
    watch: {
        email(){
            this.updateEmail();
        },
        editedDocument: {
            immediate: true,
            handler: function(){
                if(this.editedDocument && this.editedDocument.email){
                    this.email = this.editedDocument.email.html
                    this.emailJson = this.editedDocument.email.json;
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/_variables.scss';
.v-sheet.v-card{

    .v-card__title{
        border-bottom: none !important;
        padding: 0px;

        overflow: hidden;
        box-shadow: 0px 4px 7px rgba(50, 50, 50, 0.1);
    }

    .v-card__text .v-tabs-items{
        background: transparent;
    }
}
.v-tab{
    text-transform: none;

    .v-icon{
        margin-right: 10px;
    }
}

.email-message {
    margin-top: 15px;

    ::v-deep {

        .email-toolbar .col.buttons {
            justify-content: flex-start;
        }

        .email-toolbar{
            margin-top: 10px;
        }

        .v-input .v-text-field__details{
            display: none;
        }

        .editor-holder {
            border: 1px solid $color-gray--50;
            border-radius: 5px;
            transition: all 0.5s;
            padding: .5rem;
            height: 350px;

            &:hover{
                border: 2px solid $color-secondary--normal;
            }

            &.ProseMirror-focused{
                border: 2px solid $color-secondary--normal;
            }
        }
    }
}

.color-primary .email-message::v-deep{
    .editor-holder {

        &:hover{
            border: 2px solid $color-primary--normal;
        }

        &.ProseMirror-focused{
            border: 2px solid $color-primary--normal;
        }
    }
}
</style>
