<template lang="pug">
  .document-signed
      v-container
        v-row
            v-col(sm="12")
                DashboardWidget
                    template(#title)
                    .icon
                        v-icon(color="green darken-1") mdi-file-document-check
                    .text Hooray
                    .sub-text You successfully signed your contract.
                    .actions
                        v-btn.view(color="primary" :to="{name: 'view_contract', params: {id: $route.params.uuid}}") View the signed contract

</template>

<script>
import DashboardWidget from "../../components/dashboard/DashboardWidget";
import EditorPreview from "../../components/EditorPreview";

export default {
    components: {EditorPreview, DashboardWidget},
    methods: {
    },

}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.document-signed{
    height: 100%;
    display: flex;

    align-items: center;
}

::v-deep .widget{
    width: 100%;
    display: flex;
    padding: 15px;
}

.actions{
    display: flex;
    justify-content: center;
}

.icon{
    text-align: center;

    margin-top: 75px;

    .v-icon{
        font-size: 175px;
    }
}

.text{
    font-weight: 700;
    font-size: 55px;

    text-align: center;
    color: $color-green--normal;
    padding-top: 75px;
}

.sub-text{
    font-weight: 500;
    font-size: 35px;
    padding-top: 75px;
    padding-bottom: 75px;
    color: $color-gray--100;
    text-align: center;
}
</style>
