<template lang="pug">
    .document-edit(:class="{sending}")
        v-container(fluid)
            .actions
                v-btn.delete(outlined color="accent" v-if="!allowDelete" :to="{name: 'documents_manager'}") Discard
                v-btn.dismiss(outlined color="primary" v-if="allowDelete" @click="dismissDocument") Dismiss
                v-spacer
                v-menu.actions-menu(bottom nudge-bottom offset-y left)
                    template(#activator="{ on, attrs }")
                        v-btn.action(outlined color="primary" v-bind="attrs" v-on="on")
                            v-icon mdi-cog-outline
                    v-list.document-actions
                        v-list-item.duplicate(@click="duplicateDocument" dense)
                            v-list-item-icon
                                v-icon mdi-content-copy
                            v-list-item-title() Duplicate document
                        v-list-item.delete(@click="deleteDocument" v-if="allowDelete" dense)
                            v-list-item-icon
                                v-icon mdi-trash-can-outline
                            v-list-item-title() Delete draft
                v-btn.save(color="primary" :disabled="disableSend && !sending" @click="sendDocument") Send contracts
                v-snackbar(v-model="duplicated" color="primary") The document has been duplicated
            v-row
                v-col(sm="12" lg="8")
                    EditorTab(color="primary" type="document")
                v-col(lg="4")
                    RelatedTab(color="primary" type="document")
</template>

<script>
import api from '@/api'; 
import EditorTab from "../../components/EditorTab";
import RelatedTab from "../../components/RelatedTab";
import {mapState} from "vuex";
import Spinner from "../../components/Spinner";

export default {
    components: {Spinner, RelatedTab, EditorTab,},
    data(){
        return {
            id: this.$route.params.id,
            document: null,
            sending: false,
            duplicated: false
        }
    },

    methods: {
        async saveDocument(){
            let id = this.$route.params.id
            if(!id){
                id = 'new';
            }
            this.$store.commit('setEditedDocument', {
                songs: this.$store.state.selectedSongs.map(song => song.uuid)
            })
            return await api.saveDocument(id, this.editedDocument);
        },
        async dismissDocument(){
            this.saveDocument();
            this.$router.push({name: 'documents_manager'});
        },
        async deleteDocument(){
            await api.deleteDocument(this.$route.params.id);
            await this.$router.push({ name: 'documents_manager'});
        },
        async duplicateDocument(){
            await this.$router.push({ name: 'new_contract'});
            this.$store.commit('setEditedDocument', {...this.editedDocument, id: null});
            this.duplicated = true;
        },
        async sendDocument(){
            if(this.sending){
                return;
            }
            this.$store.commit('showSpinner', true);
            this.sending = true;
            const document = await this.saveDocument();
            console.log(document);
            await api.sendDocument(document.id, this.editedDocument);
            await this.$router.push({ name: 'contract_sent'});
        }
    },

    computed:{
        ...mapState({
            selectedTemplate: state => state.selectedTemplate,
            selectedSongs: state => state.selectedSongs,
            editedDocument: state => state.editedDocument
        }),
        disableSend(){
            return this.selectedTemplate !== null || this.selectedSongs.length === 0;
        },
        allowDelete(){
            return this.editedDocument.id && !this.editedDocument.status
        }
    },

 
    async beforeMount(){
        if(this.id){
            this.$store.commit('showSpinner', true);
            const document = await api.getDocument(this.id);
            if(document.status){
                this.$router.replace({name: 'preview_contract', params: {id: this.id}})
                return;
            }
            this.$store.commit('setEditedDocumentFromApi', document);

            if(document.songs){
                this.$store.commit('setSelectedSongs', await api.getSongsByUUID(document.songs))
                this.$store.commit('setSelectedSongUUIDs', document.songs)
            }
            this.$store.commit('showSpinner', false);
        }else{
            this.$store.commit('resetEditedDocument', {});
        }
    },
    beforeDestroy() {
        this.$store.commit('setSelectedSongs', []);
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.document-edit{

    &.sending{
        opacity: 0.35;
        pointer-events: none;

        position: relative;

        .spinner{
            position: fixed;
            top: calc(50vh - 35px);
            left: calc(50vw - 50px);
        }
    }



    .actions{
        display: flex;
        padding-top: 25px;
        padding-bottom: 35px;

        .v-btn{

            &.dismiss{
                margin-right: 15px;
            }

            &.draft{
                margin-right: 15px;
            }

            &.v-btn--outlined::before{
                background: transparent;
            }

            &.action{
            }
        }
    }
}

</style>
