<template lang="pug">
  .template-edit
      v-container(fluid)
        .actions
            v-btn.dismiss(outlined color="secondary darken-1" :to="{name: 'template_manager'}") Dismiss
            v-spacer
            v-menu.actions-menu(bottom nudge-bottom offset-y left)
                template(#activator="{ on, attrs }")
                    v-btn.action(outlined color="secondary" v-bind="attrs" v-on="on")
                        v-icon mdi-cog-outline
                v-list.document-actions
                    v-list-item.use(@click="useTemplate" dense)
                        v-list-item-icon
                            v-icon mdi-file-document-multiple-outline
                        v-list-item-title Use template in new contract
                    v-list-item.delete(@click="deleteTemplate" dense v-if="$route.params.id")
                        v-list-item-icon
                            v-icon mdi-trash-can-outline
                        v-list-item-title Delete template

            v-btn.save(color="secondary darken-1" @click="saveTemplate") Save template
            v-snackbar(v-model="saved" color="secondary") The template has been saved
        v-row
            v-col(sm="8")
                EditorTab(color="secondary" ref="editor")
            v-col(sm="4")
                RelatedTab(color="secondary")
</template>

<script>
import api from '@/api';
import EditorTab from "../../components/EditorTab";
import RelatedTab from "../../components/RelatedTab";

export default {
    components: {RelatedTab, EditorTab,},
    data(){
        return {
            id: this.$route.params.id,
            template: null,
            saved: false,
        }
    },

    methods: {
        async useTemplate(){
            await this.$router.push({ name: 'new_contract'});
            this.$store.commit('setEditedDocumentFromApi', {...this.template, id: null});
        },
        saveTemplate(){
            this.$refs.editor.saveDocument();
            this.saved = true;
        },
        async deleteTemplate(){
            await api.deleteTemplate(this.$route.params.id);
            await this.$router.push({ name: 'template_manager'});
        },
    },


    async beforeMount(){
        if(this.$route.name !== 'new_template'){
            this.template = await api.getTemplate(this.id);
            this.$store.commit('setEditedDocumentFromApi', this.template);
        }else{
            this.$store.commit('resetEditedDocument');
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.template-edit{



    .actions{
        display: flex;
        padding-top: 25px;
        padding-bottom: 35px;

        .v-btn{

            &.dismiss{
                margin-right: 15px;
            }
            &.use{
                margin-right: 15px;
            }

            &.v-btn--outlined::before{
                background: transparent;
            }
        }
    }
}

</style>
