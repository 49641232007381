<template lang="pug">
  .templates-manager
      .banner
      v-container
        .new-button
            v-btn.new(color="secondary darken-1" to="templates/new" large) + New template
        v-card
          v-card-title Template list
          v-card-text
            v-data-table(
                :headers="songHeaders"
                :items="documents"
                :loading="loadingDocuments"
                @update:sort-by="sortDocuments"
                @update:sort-desc="orderDesc"
                :sort-by="documentParams.sort"
                :sort-desc="documentParams.desc"
                :server-items-length="25"
                :items-per-page="25"
                hide-default-footer
                item-key="uuid"
            )
                template(#item.name="{ item }")
                    router-link.document-name(:to="`/templates/${item.id}`" v-if="item.name") {{item.name}}
                    router-link.document-name(:to="`/templates/${item.id}`" v-else) [no title]
                template(#item.created_at="{ item }")
                    span.date(v-if="item.created_at") {{formatDate(item.created_at) }}
                    span.n-a(v-else) N/A
                template(#item.updated_at="{ item }")
                    span.date(v-if="item.updated_at") {{formatDate(item.updated_at) }}
                    span.n-a(v-else) N/A
                template(#loading)
                    .table-spinner
                        Spinner
                template(#item.actions="{ item }")
                    .actions-holder
                        v-tooltip(left color="secondary") Edit template
                            template(#activator="{ on, attrs }")
                                v-btn.action-button(color="secondary" :to="`/templates/${item.id}`" small icon v-bind="attrs" v-on="on")
                                    v-icon.action mdi-pencil
                        v-tooltip(left color="primary") New contract with this template
                            template(#activator="{ on, attrs }")
                                v-btn.action-button(color="primary" small icon v-bind="attrs" v-on="on" @click="newFromTemplate(item.id)")
                                    v-icon.action mdi-file-outline
                        v-tooltip(left color="accent") Delete template
                            template(#activator="{ on, attrs }")
                                v-btn.action-button(color="accent" small icon v-bind="attrs" v-on="on" @click="deleteTemplate(item.id)")
                                    v-icon.action mdi-trash-can-outline
            v-pagination(v-model="page" :length="pages"  color="secondary" @input="getPage")

</template>

<script>
import api from '@/api';
import { format } from 'date-fns'
import Spinner from "../../components/Spinner";

export default {
    components: {Spinner},
    data(){
        return {
            documents: [],
            documentsData: {},
            loadingDocuments: true,
            songs: [],
            selectedSongs: [],
            documentFilter: '',
            page: 1,
            searchTimeout: null,
            selectedStatuses: ['draft', 'sent', 'read', 'signed'],
            documentParams: {
                sort: 'updated_at',
                desc: true,
                paginate: 25,
                page: 1
            },
            statuses: {
                'colors':['accent lighten-1', 'amber darken-2', 'primary', 'secondary'],
                'names': ['Draft', 'Sent', 'Read', 'Signed'],
                'icons': ['mdi-pencil', 'mdi-email-outline', 'mdi-eye-outline', 'mdi-check'],
                'class': ['draft', 'sent', 'read', 'signed']
            },
            songHeaders: [
                {text: 'ID', value: 'id', sortable: false},
                {text: 'Title', value: 'name', sortable: true},
                {text: 'Description', value: 'description', sortable: false},
                {text: 'Added date', value: 'created_at', sortable: true},
                {text: 'Updated date', value: 'updated_at', sortable: true},
                {text:'', value: 'actions', class: 'actions', sortable: false}
            ]
        }
    },

    methods:{
        formatDate(date){
            return format(new Date(date), 'MM/dd/yyyy hh:mmaaa')
        },
        updateSelected(){
            this.$store.commit('setSelectedSongUUIDs', this.selectedSongs.map(song => song.uuid));
        },
        getPage(page){
            this.documentParams.page = page;
            this.getDocuments()
        },
        sortDocuments(sort){
            this.documentParams.sort = sort;
            this.getDocuments()
        },
        orderDesc(desc){
            this.documentParams.desc = desc || null;
            this.getDocuments()
        },
        resetFilters(){
            this.documentParams = {
                sort: 'name',
                desc: null,
                paginate: 25,
                page: 1
            };
            this.getDocuments();
        },
        async deleteTemplate(id){
            await api.deleteTemplate(id);
            await this.getDocuments();
        },
        async newFromTemplate(id){
            const template = await api.getTemplate(id);
            await this.$router.push({ name: 'new_contract'});
            this.$store.commit('setEditedDocumentFromApi', {...template, id: null});
        },
        async getDocuments(){
            this.loadingDocuments = true;
            this.documentsData = await api.getTemplates(this.documentParams);
            this.documents = this.documentsData.data;
            this.loadingDocuments = false;
        },
    },

    computed: {
        pages(){
            let pages = 1;
            if(this.documentsData.last_page){
                pages = this.documentsData.last_page;
            }
            return pages;
        }
    },

    async beforeMount(){
        await this.getDocuments();
    }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/styles/_variables.scss';

    .templates-manager{
        position: relative;

        .new-button{
            display: flex;
            justify-content: flex-end;
            padding-top: 25px;
            padding-bottom: 40px;

            .v-btn{
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
        }

        .table-spinner{
            display: flex;
            justify-content: center;
            align-items: center;

            height: 350px;
            margin-top: 15px;

            .spinner{
                filter: hue-rotate(-30deg) brightness(150%) saturate(150%);
            }
        }

        .banner{
            width: 100%;
            height: 300px;
            background-image: url('../../assets/images/templates.jpg');
            position: absolute;
            background-size: cover;
            opacity: 0.25;

            &:before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                background: linear-gradient(180deg, rgba(237,94,81,0.35) 0%, rgba(237,247,250,0.5) 50%, rgba(255,255,255,1) 100%);
                backdrop-filter: blur(1px);
            }
        }

        .search-input::v-deep{
            margin-top: 15px;
            padding-top: 0px;
            border: 1px solid $color-gray--50;
            margin-bottom: 30px;

            .v-input__slot{
                margin-bottom: 0px;

                &:before{
                    display: none;
                }

                &:after{
                    display: none;
                }

            }

            .v-text-field__details{
                display: none;
            }
        }

        .status-filters{
            display: flex;
            border-top: 1px solid $color-primary--dark;
            margin-top: 0px;
            padding-top: 10px;

            .v-input--checkbox{
                margin-right: 20px;
            }
        }

        ::v-deep .v-pagination{
            margin-top: 50px;
        }

        .chip-holder{
            width: 100px;
        }

        .v-chip{
            max-width: 32px;
            transition: max-width 0.5s;

            &:hover{
                max-width: 100px;
            }
        }

        .v-avatar{
            margin-left: -8px;
        }

        .v-chip .v-icon{
            font-size: 16px;
            padding: 16px;
        }

        .n-a{
            color: $color-gray--75;
        }

        .v-icon.action{
            font-size: 21px;
        }

        a.document-name{
            text-decoration: none;
            font-weight: bold;

            color: $color-secondary--normal;
        }

        .signer-name{
            font-weight: bold;
            color: $color-gray--75;
        }

        .action-button{
            padding-left: 8px;
            padding-right: 8px;

            margin-left: 8px;
        }

        ::v-deep .v-progress-linear__background{
            background-color: $color-secondary--dark !important;
        }

        ::v-deep .v-progress-linear__indeterminate{
            background-color: $color-secondary--dark !important;
        }

        ::v-deep .actions{
        }

        .actions-holder{
            text-align: right;
        }

    }

    #app .v-sheet.v-card{

        .v-card__title{
            //border-bottom: 1px solid $color-secondary--dark;
        }
    }
</style>
