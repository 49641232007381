<template lang="pug">
    .song()
        .song-info(:class="{selected: dropdown, invalid}" @click="toggleSong")
            .status
                v-icon(v-if="invalid") mdi-alert-circle-outline
                v-icon(v-else) mdi-check-circle-outline
            .song-name
                .name {{song.name}}
                .artist {{song.artist}}
            .dropdown-holder
                v-icon(v-if="dropdown") mdi-chevron-up
                v-icon(v-else) mdi-chevron-down
        .dropdown(v-if="dropdown")
            div.rights Songwriting
                v-spacer
                .percentage(:class="{invalid: song.songwriting !== 100}") {{song.songwriting}}%
            div.rights Publishing
                v-spacer
                .percentage(:class="{invalid: song.publishing !== 100}") {{song.publishing}}%
            div.rights Master
                v-spacer
                .percentage(:class="{invalid: song.mastering !== 100}") {{song.mastering}}%
            v-btn.dismiss(color="accent" outlined small block @click="deleteSong") Delete from list

</template>

<script>
export default {
    props: ['song'],

    data() {
        return {
            dropdown: false
        }
    },
    methods:{
        toggleSong(){
            this.dropdown = !this.dropdown;
        },
        deleteSong(){
            const selectedSongs = this.$store.state.selectedSongs.filter(song => song.id !== this.song.id);
            this.$store.commit('setSelectedSongs', selectedSongs);
        }
    },


    computed:{

        invalid(){
            return this.song.songwriting !== 100 || this.song.publishing !== 100 || this.song.mastering !== 100;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';



%selected{
    background: $color-green--opacity;
    cursor: pointer;
    border-color: $color-green--opacity;

    .status .v-icon{
        //color: #FFFFFF;
    }

    .song-name{
        //color: #FFFFFF;

        .artist{
            color: $color-green--normal;
        }
    }
}

%selected-amber{
    background: $color-amber--opacity;
    border-color: $color-amber--opacity;

    .status .v-icon{
        //color: #FFFFFF;
    }

    .song-name{

        .artist{
            color: $color-amber--normal;
        }
    }
}

.dropdown{
    padding: 1rem;

    .rights{
        display: flex;
        margin-top: 5px;
        margin-bottom: 5px;

        .percentage{
            color: $color-green--normal;

            &.invalid{
                color: $color-accent--normal;
            }
        }
    }

    .v-btn{
        margin-top: 25px;
    }
}


.song-info{
    display: flex;
    padding: 1rem;
    align-items: center;
    border: 1px solid $color-gray--25;
    border-radius: 0px;

    &:hover{
        @extend %selected;
    }



    .status{

        .v-icon{
            font-size: 50px;
            color: $color-green--normal;
            font-weight: bold;
        }
    }

    .song-name{
        width: 100%;
        text-align: left;
        padding-left: 15px;

        div{
            display: block;
        }

        .name{
            font-weight: bold;
        }

        .artist{
            color: $color-gray--75;
        }
    }

    .checkbox{
        width: 19px;
        height: 19px;

        border: 1px solid $color-green--normal;
        display: flex;
        justify-content: center;

        .v-icon{
            color: #FFFFFF;
            font-size: 14px;

            display: none;
        }
     }

    &.selected{
        @extend %selected;
        box-shadow: 0px 20px 35px rgba(9, 61, 19, 0.05);

        .checkbox{
            background: $color-green--normal;

            .v-icon{
                display: inline;
            }
        }
    }


    &.invalid{
        border: 1px solid $color-gray--25;

        &:hover{
            @extend %selected-amber;
        }

        .checkbox{
            border: 2px solid $color-amber--normal;
        }

        .status{

            .v-icon{
                color: $color-amber--normal;
            }
        }

        &.selected{
            @extend %selected-amber;

            .checkbox{
                background: $color-amber--normal;
            }
        }

    }
}
</style>
