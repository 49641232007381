<template lang="pug">
.account-manager
  .banner
  v-container
    .new-button
        v-btn.new(color="amber" @click="showUserPopup({})" large) + New user
    v-card
      v-card-title User list for {{accountName}}
      v-card-text
          v-snackbar(v-model="showDeleteSnackbar" color="accent") {{deleteMessage}}
          v-snackbar(v-model="showAddSnackbar") User saved
          v-dialog(v-model="userPopup" transition="dialog-top-transition" width="500")
              v-card
                  v-card-title(v-if="editedUser && editedUser.id") Edit user details
                  v-card-title(v-else) Add new user
                  v-card-text
                      v-text-field(v-model="editedUser.first_name" label="First name")
                      v-text-field(v-model="editedUser.last_name" label="Last name")
                      v-text-field(v-model="editedUser.email" label="Email address")
                      v-select(:items="roles" v-model="editedUser.role" item-text="name" dense label="Role")
                      v-text-field(v-model="editedUser.password" label="Password" type="password")
                  v-card-actions
                      v-btn(color="primary" outlined @click="dismiss") Dismiss
                      v-spacer
                      v-btn(color="primary" @click="save") Save
          v-dialog(v-model="deletePopup" transition="dialog-top-transition" width="500")
              v-card
                  v-card-title Delete user {{editedUser.first_name }} {{ editedUser.last_name}}
                  v-card-text
                    div Are you sure you want to delete
                        strong &nbsp;{{editedUser.first_name }} {{ editedUser.last_name}} ({{ editedUser.email }})
                        | ?
                  v-card-actions
                      v-btn(color="primary" outlined @click="dismiss") Dismiss
                      v-spacer
                      v-btn(color="accent" @click="deleteUser") Delete

          v-data-table(
              :headers="userHeaders"
              :items="users"
              :loading="loading"
              :items-per-page="-1"
              hide-default-footer
              item-key="uuid"
          )
              template(#item.first_name="{ item }")
                  .user-name {{item.first_name}}
              template(#item.last_name="{ item }")
                  .user-name {{item.last_name}}
              template(#item.email="{ item }") {{item.email}}
              template(#item.roles="{ item }")
                  .user-role(v-if="item.roles && item.roles.SONOCUTS_CONTRACTS") {{item.roles.SONOCUTS_CONTRACTS}}
                  .user-role(v-else) N/A
              template(#item.created_at="{ item }")
                  span.date(v-if="item.created_at") {{formatDate(item.created_at) }}
                  span.n-a(v-else) N/A
              template(#item.email_verified_at="{ item }")
                  span.date(v-if="item.email_verified_at") {{formatDate(item.email_verified_at) }}
                  span.n-a(v-else) N/A
              template(#loading)
                  .table-spinner
                      Spinner
              template(#item.actions="{ item }")
                  .actions-holder
                      v-tooltip(left color="amber") Edit user
                          template(#activator="{ on, attrs }")
                              v-btn.action-button(color="amber" @click="showUserPopup(item)" small icon v-bind="attrs" v-on="on")
                                  v-icon.action mdi-pencil
                      v-tooltip(left color="accent") Delete user
                          template(#activator="{ on, attrs }")
                              v-btn.action-button(color="accent" small icon v-bind="attrs" v-on="on" @click="deleteShow(item)")
                                  v-icon.action mdi-trash-can-outline

</template>

<script>
import api from '@/api';
import { format } from 'date-fns'
import Spinner from "../../components/Spinner";

export default {
    components: {Spinner},
    data(){
        return {
            users: [],
            loading: true,
            userHeaders: [
                {text: 'First name', value: 'first_name', sortable: true},
                {text: 'Last name', value: 'last_name', sortable: true},
                {text: 'Email', value: 'email', sortable: true},
                {text: 'Role', value: 'roles', sortable: true},
                {text: 'Registered at', value: 'created_at', sortable: true},
                {text: 'Email verified at', value: 'email_verified_at', sortable: true},
                {text:'', value: 'actions', class: 'actions', sortable: false}
            ],
            roles: [
                {
                    name: 'Administrator',
                    value: 'ADMIN'
                },
                {
                    name: 'User',
                    value: 'USER'
                },
            ],
            editedUser: {},
            userPopup: false,
            deletePopup: false,
            deleteMessage: '',
            showDeleteSnackbar: false,
            showAddSnackbar: false,
        }
    },

    methods:{
        formatDate(date){
            return format(new Date(date), 'MM/dd/yyyy hh:mmaaa')
        },
        async save(){
            await api.saveUser(this.editedUser);
            this.getUsers();
            this.userPopup = false;
            this.showAddSnackbar = true;
        },

        showUserPopup(user){
            this.editedUser = user;
            this.editedUser.role = this.editedUser.roles && this.editedUser.roles.SONOCUTS_CONTRACTS;
            this.userPopup = true;
        },
        dismiss(){
            this.userPopup = false;
            this.deletePopup = false;
        },
        deleteShow(user){
            this.editedUser = user;
            this.deletePopup = true;
        },
        async deleteUser(){
            const result = await api.deleteUser(this.editedUser.id);
            this.deleteMessage = result.message;
            this.getUsers();
            this.deletePopup = false;
        },
        async getUsers(){
            this.loading = true;
            this.users = await api.getUsers();
            this.loading = false;
        }
    },

    computed: {
        allowSave(){
            return true;
        },
        accountName(){
            return this.$store.state.user && this.$store.state.user.account.name;
        }
    },

    async beforeMount(){
        this.getUsers();
    }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/styles/_variables.scss';

    .account-manager{
        position: relative;

        .new-button{
            display: flex;
            justify-content: flex-end;
            padding-top: 25px;
            padding-bottom: 40px;

            .v-btn{
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                color: #FFFFFF;
            }
        }

        .table-spinner{
            display: flex;
            justify-content: center;
            align-items: center;

            height: 350px;
            margin-top: 15px;

            .spinner{
                filter: hue-rotate(-30deg) brightness(150%) saturate(150%);
            }
        }

        .banner{
            width: 100%;
            height: 300px;
            //background-image: url('../../assets/images/templates.jpg');
            position: absolute;
            background-size: cover;
            opacity: 0.25;

            &:before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                background: linear-gradient(180deg, rgba(237,94,81,0.35) 0%, rgba(237,247,250,0.5) 50%, rgba(255,255,255,1) 100%);
                backdrop-filter: blur(1px);
            }
        }

        .n-a{
            color: $color-gray--75;
        }

        .v-icon.action{
            font-size: 21px;
        }

        a.user-name{
            text-decoration: none;
            font-weight: bold;

            color: $color-secondary--normal;
        }

        .action-button{
            padding-left: 8px;
            padding-right: 8px;

            margin-left: 8px;
        }

        ::v-deep .v-progress-linear__background{
            background-color: $color-secondary--dark !important;
        }

        ::v-deep .v-progress-linear__indeterminate{
            background-color: $color-secondary--dark !important;
        }

        ::v-deep .actions{
        }

        .actions-holder{
            text-align: right;
        }

    }

    #app .v-sheet.v-card{

        .v-card__title{
            //border-bottom: 1px solid $color-secondary--dark;
        }
    }
</style>
